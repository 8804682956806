import { template as template_5fa86ca6fee14498bcd6c5cced721cfc } from "@ember/template-compiler";
const WelcomeHeader = template_5fa86ca6fee14498bcd6c5cced721cfc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
