import { template as template_721c583fedba4769b590be4a4e2d8b9f } from "@ember/template-compiler";
const FKControlMenuContainer = template_721c583fedba4769b590be4a4e2d8b9f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
